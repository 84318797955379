import React, { useState, useEffect } from "react";
import { OperatorProfileImage, shortcutmessages } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { DeleteProfilePic, setNewAvatar } from "../../slices/user";
import { Avatar, Button, CircularProgress } from "@mui/material";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { dashToEmptySpace } from "../../utilities/dashToEmptySpace";
import { useTranslation } from "react-i18next";
const ProfileImage = ({
  className,
  id,
  btnClassName,
  userImage,
  setShortcutImage,
  shortcutImage,
  data,
}) => {
  const { user, statusDeleteProfilePicture } = useSelector(
    (state) => state.user
  );
  const { t } = useTranslation();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const [image, setImage] = useState(
    id === "update-image"
      ? user.avatar
      : id === "add-operator-drawer"
      ? userImage
      : OperatorProfileImage
  );
  useEffect(() => {
    setImage(
      id === "update-image"
        ? user.avatar
        : id === "add-operator-drawer"
        ? userImage
        : OperatorProfileImage
    );
  }, [user]);
  const dispatch = useDispatch();
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      id === "add-shortcut-image" &&
        setShortcutImage(URL.createObjectURL(event.target.files[0]));
      dispatch(setNewAvatar(event.target.files[0]));
    }
  };
  let inputRef;

  return (
    <div
      className={className ? `image-profile ${className}` : `image-profile`}
      style={{ height: id === "drop-message" && "100vh" }}
    >
      {/* <div className="upload-image-profile"> Profile Image </div> */}
      <div
        className="img-file-wrapper"
        style={{ alignItems: id === "drop-message" && "center" }}
      >
        <Avatar
          className="profile-avatar"
          src={
            id === "add-shortcut-image"
              ? shortcutImage
              : id === "drop-message"
              ? data?.client?.avatar
              : image
          }
          alt="preview image"
        />
        <div className="text-btns-wrapper">
          <div>
            {id === "add-operator-drawer"
              ? t("Upload User Profile Image")
              : id === "add-shortcut-image"
              ? dashToEmptySpace(`add-shortcut-image`)
              : id === "drop-message"
              ? data?.client?.name
              : t("Edit Your Profile")}
          </div>
          {id === "add-operator-drawer" ? (
            <span className="file-size">
              ({t("File size")}: max 1024 KB |{t(" Formats")}: .PNG, .JPG)
            </span>
          ) : null}
          {id !== "drop-message" && (
            <div className="input-delete-btn-wrapper">
              <button
                type="button"
                name="upload-btn"
                className={`upload-btn ${btnClassName}`}
                onClick={() => {
                  inputRef.click();
                }}
              >
                {t("Upload")}
              </button>
              <input
                type="file"
                onChange={onImageChange}
                className="filetype"
                accept=".jpg, .png"
                ref={(refParam) => (inputRef = refParam)}
              />
              {id === "add-operator-drawer" ||
              id === "add-shortcut-image" ? null : (
                <button
                  className="delete-btn"
                  type="button"
                  disabled={
                    statusDeleteProfilePicture === "loading" ? true : false
                  }
                  onClick={() =>
                    image !== OperatorProfileImage &&
                    user?.avatar !== null &&
                    (() => {
                      setImage(OperatorProfileImage);
                      dispatch(setNewAvatar(null));
                      dispatch(DeleteProfilePic()).then((res) => {
                        if (res.meta.requestStatus === "fulfilled") {
                          showSnackbar({
                            variant: "success",
                            message: "Profile Picture Deleted Successfully",
                          });
                        } else {
                          showSnackbar({
                            variant: "error",
                            message: res?.error?.message,
                          });
                        }
                      });
                    })()
                  }
                >
                  {statusDeleteProfilePicture === "loading" ? (
                    <CircularProgress size={15} style={{ color: "#ed3863" }} />
                  ) : (
                    t("Delete")
                  )}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileImage;
