import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOperator } from "../../slices/operaters";
import userIcon from "../../assets/icons/user.svg";
import { resetTables } from "../../slices/statistics";
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";

const Operator = ({ user, className, i, clicked }) => {
  const dispatch = useDispatch();
  const { operator } = useSelector((state) => state.operators.operator);
  const { t } = useTranslation();
  const handleClick = (user) => {
    if (user?.operator?._id !== operator?._id) dispatch(resetTables());
    dispatch(setOperator(user));
  };
  let totalMessages = [];
  if (clicked === 0) {
    const { totalMessagesPerOperatorPerDay } = useSelector(
      (state) => state.statistics
    );
    totalMessages = totalMessagesPerOperatorPerDay;
  } else {
    const { totalMessagesPerOperatorPerWeek } = useSelector(
      (state) => state.statistics
    );
    totalMessages = totalMessagesPerOperatorPerWeek;
  }
  return (
    <li
      className={`list-item ${className} ${
        user?.operator?._id === operator?._id ? "active-operator" : ""
      } `}
      onClick={() => handleClick(user)}
    >
      <div className="number">{i}</div>
      <Avatar
        src={user?.operator?.avatar}
        alt="profile-img"
        sx={{ width: "64px", height: "64px" }}
      />
      <div className="list-item-content">
        <div className="operator-name">
          {user?.operator?.firstName} {user?.operator?.lastName}
        </div>
        <div className="conversations-number">
          {" "}
          {user?.count} {t("conversations")}{" "}
          {totalMessages?.map(
            (el) =>
              el._id === user.operator?._id && (
                <div>
                  {" "}
                  ({el.count} {t("messages")})
                </div>
              )
          )}
        </div>
      </div>
    </li>
  );
};

export default Operator;
