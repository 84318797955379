import React from "react";
import Operator from "./Operator";
import { useTranslation } from "react-i18next";
const Operators = ({ users, clicked }) => {
  const { t } = useTranslation();
  return (
    <div className="list-wrapper">
      <ul className="list">
        {users && users.length > 0 ? (
          users.map((user, i) => (
            <Operator
              user={user}
              key={user?.operator?._id}
              i={i + 1}
              className={i % 2 === 0 ? "gray" : ""}
              clicked={clicked}
            />
          ))
        ) : (
          <span className="no-operators-analytics">{t("No Operators")}</span>
        )}
      </ul>
    </div>
  );
};
export default Operators;
