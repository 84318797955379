import React from "react";
import { useSelector } from "react-redux";
const RoleGuard = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const isAllowed = () => {
    if (user?.role?.code === "OPERATOR") {
      return false;
    }
    return true;
  };

  if (!isAllowed()) return null;

  return <>{children}</>;
};

export default RoleGuard;
